.country-info-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f0f0f0; /* Ash color background */
    border-radius: 8px;
    font-family: Arial, sans-serif;
  }
  
  .country-info-title {
    color: #464183; /* Primary color for title */
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .country-info-subtitle {
    color: #ff0000; /* Red color for subtitle */
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
  
  .country-info-heading {
    color: #ff0000; /* Red color for section headings */
    font-size: 1.5rem;
    margin-top: 20px;
  }
  
  .country-info-paragraph {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 15px;
  }
  
  @media (max-width: 768px) {
    .country-info-title {
      font-size: 2rem;
    }
  
    .country-info-subtitle {
      font-size: 1.5rem;
    }
  
    .country-info-heading {
      font-size: 1.3rem;
    }
  
    .country-info-paragraph {
      font-size: 0.9rem;
    }
  }
  