.testimonial-section {
    text-align: center;
    padding: 50px;
    background-color: #f9f9f9;
  }
  
  .testimonial-heading {
    font-size: 2.5rem;
    color: #464183;
    margin-bottom: 40px;
  }
  
  .testimonial-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .testimonial-card {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .testimonial-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .testimonial-image {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }
  
  .testimonial-card h3 {
    font-size: 1.5rem;
    color: #464183;
    margin: 10px 0;
  }
  
  .testimonial-card .country {
    font-size: 1rem;
    color: #666;
    margin-bottom: 10px;
  }
  
  .testimonial-card .feedback {
    font-size: 1.1rem;
    font-style: italic;
    color: #333;
  }
  