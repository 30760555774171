.carousel {
  position: relative;
  width: 100%;
  height: 30vh; /* 30% of the viewport height */
  overflow: hidden;
}

.carousel-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 1s ease-in-out;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-slide.active {
  opacity: 1;
}

.carousel-text {
  background-color: transparent;
  color: white;
  padding: 15px 25px;
  border-radius: 5px;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;

}
