.programs-container {
    background-color: #1e1d23;
    padding: 40px;
    text-align: center;
  }
  
  .title {
    color: white;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .programs-grid {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .program-card {
    background-color: #2b2a31;
    padding: 20px;
    border-radius: 8px;
    width: 200px;
    transition: background-color 0.3s; /* Smooth transition for background color */
  }
  
  .program-card img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .program-card h3 {
    color: white;
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  .program-card:hover {
    background-color: red; /* Change background color to red on hover */
  }
  
  .enquiry-button {
    background-color: red;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .enquiry-button:hover {
    background-color: white;
    color: black;
    transition: 0.3s;
  }
  