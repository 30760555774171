.study-destinations-container {
    text-align: center;
    padding: 50px 20px;
    background-color: #f5f5f5;
  }
  
  .study-destinations-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 40px;
    justify-items: center;
  }
  
  .country-card {
    width: 240px;
    height: 300px;
    border-radius: 15px;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transition: transform 0.1s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1); /* Initial state */
  }
  
  .flag-image {
    width: 100%;
    height: 160px;
    object-fit: cover;
  }
  
  .country-card p {
    margin: 10px 0 0;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    border-top: 1px solid #eee;
    width: 100%;
  }
  