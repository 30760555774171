.enquiry-form-container {
  background-color: black;
  color: white;
  padding: 50px;
  max-width: 1200px; /* Restrict width */
  margin: 0 auto; /* Center the container */
  border-radius: 10px;
  width: 80%;
  margin-top: 35px;
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.form-image-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-container {
  width: 50%;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%; /* Equal width for all inputs */
  padding: 12px; /* Increased padding for better feel */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #333;
  color: white;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #999;
}

.error {
  border-color: red;
}

.error-text {
  color: red;
  font-size: 0.9rem;
}

.submit-button {
  background-color: red;
  color: white;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1rem;
}

.submit-button:hover {
  background-color: darkred;
}

.image-container {
  width: 40%;
}

.image-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Card elevation */
  padding: 20px;
  text-align: center;
}

.image-card img {
  max-width: 100%;
  border-radius: 10px;
  height: auto;
}

/* Popup styles */
.popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid #464183;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  z-index: 1000;
}

.popup p {
  font-size: 18px;
  margin-bottom: 10px;
  font-family: Arial, sans-serif;
  color: black;
}

.popup button {
  background-color: #464183;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.popup button:hover {
  background-color: #363073;
}

/* Loader styles */
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.spinner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #464183;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* General form error styles */
.error {
  border: 1px solid red;
}

.error-text {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
